/* eslint-disable */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Page from '../../components/page'
import withRoot from '../../withRoot'

const styles = theme => ({
  paragraph: {
    margin: theme.spacing(2),
  },
})

@withRoot
@withStyles(styles)
export default class LegalNotice extends React.Component {
  render() {
    const { classes } = this.props
    return (
        <Page toggleTheme={this.props.toggleTheme} title="Legal Notice">
          <div className={classes.paragraph} />

          <Typography className={classes.paragraph} align="center">
            Editorial manager :
          </Typography>
          <Typography align="center">
            SYSTEMD INTERNATIONAL LP
          </Typography>

          <Typography className={classes.paragraph} align="center">
            Identification number : <b>SL034277</b>
          </Typography>

          <Typography align="center">
            Address: 5 South Charlotte Street
          </Typography>
          <Typography align="center">
            Edinburgh, EH2 4AN
          </Typography>
          <Typography align="center">
            Scotland
          </Typography>
          <Typography align="center">
            United Kingdom
          </Typography>

          <Typography className={classes.paragraph} align="center">
            Email : support@fitface.xyz
          </Typography>

          <Typography className={classes.paragraph} align="center">
            Website URL: https://fitface.xyz
          </Typography>

          <Typography className={classes.paragraph} align="center" variant="h6" color="primary">
            Webmaster :
          </Typography>

          <Typography className={classes.paragraph} align="center">
            SYSTEMD INTERNATIONAL LP – support@fitface.xyz
          </Typography>

          <Typography className={classes.paragraph} align="center" variant="h6" color="primary">
            Hosting :
          </Typography>

          <Typography className={classes.paragraph} align="center">
            OVH SAS, 2 rue Kellermann, 59100 Roubaix, France
          </Typography>

        </Page>
    )
  }
}
